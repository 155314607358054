// src/components/ChatBot/StartBtn.js
import React from 'react';


const DirectSalesStaffBtn = () => {
  return (
    <button className='staff-btn'>Chat with staff</button>
  );
};

export default DirectSalesStaffBtn;
